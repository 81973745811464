<template>
  <div>
    <card class="mr-2 shadow" body-classes="standard-card-body">
      <div slot="header">
        <h4 class="card-title text-capitalize">{{ $t('callcenter.lead_data') }}</h4>
      </div>
      <ul class="list-group list-group-flush">
        <list-group-item-component
          :label="$t('fields.date_in')"
          :value="lead.date_in | date"
        />
        <list-group-item-component
          :label="$t('fields.date_out')"
          :value="lead.date_out | date"
        />
        <list-group-item-component v-if="isProviderVisible"
                                   :label="$t('common.provider')"
                                   :value="provider"/>
        <list-group-item-component v-if="lead.campaigns && lead.campaigns.length"
                                   :label="$t('common.campaigns')">
          <template slot="value">
            <div v-for="(item,index) in lead.campaigns" v-bind:key="`campaign-${index}`">
              <label-theme-component>
                {{ item.name }}
              </label-theme-component>
            </div>
          </template>
        </list-group-item-component>
        <list-group-item-component :label="$t('fields.operator')" :value="operatorName"/>
      </ul>
    </card>

    <card class="mr-2 shadow" body-classes="standard-card-body">
      <div slot="header">
        <h4 class="card-title text-capitalize">{{ $t('common.courses') }}</h4>
      </div>
      <template>
        <ul class="list-group list-group-flush">
          <li
            class="list-group-item d-flex justify-content-center bg-transparent"
            v-if="!lead.courses || !lead.courses.length"
          >
            {{ $t('common.empty_list') }}
          </li>
          <li class="standard-li" v-for="(item,index) in lead.courses" v-bind:key="`course-${index}`">
            <div class="standard-label">{{ item.title }}</div>
          </li>
        </ul>
      </template>
    </card>

    <card class="mr-2 shadow" body-classes="standard-card-body">
      <div slot="header">
        <div class="d-flex align-items-center">
          <h4 class="card-title text-capitalize">{{ $t('fields.notes') }}</h4>
          <base-button
            v-if="!readOnly"
            link
            icon
            size="sm"
            @click="openModalNote"
            class="ml-auto text-capitalize">
            <octo-icon icon="edit"/>
          </base-button>
        </div>
      </div>
      <div class="p-2 white-space-pre">
        <label-theme-component>
          {{ lead.notes }}
        </label-theme-component>
      </div>
    </card>

    <modal bodyClasses="px-2" centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('fields.notes') }}
      </h5>
      <base-text-area v-model="notes" :rows="8" class="flex-grow-1"/>
      <template slot="footer">
        <base-button link @click="saveNote">
          <div class="text-capitalize">{{ $t('common.save') }}</div>
        </base-button>
      </template>
    </modal>
  </div>

</template>

<script>
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";
import Lead from "@/models/lead";
import {permissionService} from "@/util/permission-service";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Modal from "@/components/Modal";
import BaseTextArea from "@/components/Inputs/BaseTextArea";
import {endpoints} from "@/routes/endpoints";
import ListSelectorComponent from "@/components/ListSelectorComponent";

export default {
  name: "LeadCard",
  components: {
    ListSelectorComponent,
    BaseTextArea,
    Modal, OctoIcon, LabelThemeComponent, ListGroupItemComponent, ListGroupTitleSectionComponent
  },
  data() {
    return {
      showModal: false,
      showModalOperators: false,
      notes: '',
      permissionService: permissionService,
    }
  },
  props: {
    lead: {
      type: Object,
      default: () => this.$_.cloneDeep(Lead)
    },
    readOnly: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      users: "common/users",
      operators: "common/operators",
      providers: "common/providers"
    }),

    operatorName() {

      if (!this.users[this.lead?.user_id || 0]) {
        return 'N.D.'
      }

      const user = this.users[this.lead?.user_id];

      return user?.lastname + ' ' + user?.firstname;
    },

    provider() {
      return this.$_.find(this.providers, provider => {
        return this.lead.provider_id === provider.id
      })?.name;
    },

    isProviderVisible() {
      return this.lead.provider_id && permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE])
    },
  },
  mounted() {

  },
  methods: {
    openModalNote: function () {
      this.notes = this.$_.cloneDeep(this.lead.notes);
      this.showModal = true;
    },
    saveNote: function () {
      this.$api.put(
        endpoints.LEAD_UPDATE_NOTES.replace('{id}', this.lead.id),
        {notes: this.notes}
      )
        .then(() => {
          this.lead.notes = this.notes;
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.showModal = false;
        })

    },

    changeOperator: function () {
      this.$api.put(
        endpoints.LEAD_CHANGE_OPERATOR
          .replace('{id}', this.lead.id)
          .replace('{operatorId}', this.$refs.listSelectorComponent.getSelectedItem())
      )
        .then((resp) => {
          this.$set(this.lead, 'user_id', resp?.data?.user_id);
          this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.showModalOperators = false;
        })
    }
  }
}
</script>

<style scoped>

</style>
