<template>
  <ValidationObserver ref="reactivateModal">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-12">
          <base-input class="mb-0 flex-grow-1" :label="$t('lead.reactivation_status')">
            <el-select
              v-model="reactivationStatus"
              class="select-default text-uppercase"
              :placeholder="$t('fields.choose')"
            >
              <el-option
                v-for="status in statuses"
                class="select-default text-uppercase"
                :key="status"
                :value="status"
                :label="$t('callcenter.status.' + status)"
              >
              </el-option>
            </el-select>
          </base-input>
        </div>
      </div>
      <div class="row" v-if="reactivationStatus === leadStatuses.processing.substatuses.private_recall.value">
        <div class="col-12">
          <ValidationProvider name="operator" rules="required" v-slot="{ passed, failed, errors }">
            <base-input class="mb-0 flex-grow-1"
                        :label="$t('fields.operator')"
                        :error="errors[0]"
                        :class="[{ 'has-success-select': passed }, { 'has-danger-select': failed }]">
              <el-select
                v-model="operatorId"
                class="select-default text-uppercase"
                :placeholder="$t('fields.choose')"
              >
                <el-option
                  v-for="option in operators"
                  class="select-default text-uppercase"
                  :key="option.id"
                  :value="option.id"
                  :label="$options.filters.optional(users[option.id], 'lastname') + ' ' +
                        $options.filters.optional(users[option.id], 'firstname')"
                >
                </el-option>
              </el-select>
            </base-input>
          </ValidationProvider>
        </div>
        <div class="col-12 col-md-6">
          <ValidationProvider name="date" rules="required" v-slot="{ passed, failed, errors }">
            <base-input :label="$t('fields.date')"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              <el-date-picker
                v-model="recallDate"
                type="date"
                value-format="yyyy-MM-dd"
                format="dd/MM/yyyy"
                :placeholder="$t('fields.date')"
                :picker-options="{firstDayOfWeek: 1}"
              />
            </base-input>
          </ValidationProvider>
        </div>
        <div class="col-12 col-md-6">
          <ValidationProvider name="hour" rules="required" v-slot="{ passed, failed, errors }">
            <base-input :label="$t('fields.hour')"
                        class="flex-grow-1"
                        :error="errors[0]"
                        :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
              <el-time-select
                v-model="recallTime"
                format="HH:mm"
                value-format="HH:mm"
                :clearable="false"
                :picker-options="pickerOptions"
              />
            </base-input>
          </ValidationProvider>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<script>
import ListGroupTitleSectionComponent from "@/components/ListGroupTitleSectionComponent";
import LeadStatuses from "../../CallCenter/resources/leadStatuses";
import {mapGetters} from "vuex";
import {Select, Option, DatePicker, TimeSelect} from "element-ui"

export default {
  name: "ReactivateLeadModal",
  components: {
    ListGroupTitleSectionComponent,
    [Select.name]: Select,
    [Option.name]: Option,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
  },
  data() {
    return {
      leadStatuses: LeadStatuses,
      operatorId: null,
      recallDate: '',
      recallTime: '',
      reactivationStatus: this.$_.cloneDeep(LeadStatuses.free.value),
      statuses: [
        this.$_.cloneDeep(LeadStatuses.free.value),
        this.$_.cloneDeep(LeadStatuses.processing.substatuses.private_recall.value)
      ]
    }
  },
  computed: {
    ...mapGetters({
      operators: 'common/operators',
      users: 'common/users',
      settings: 'common/settings',
    }),
    pickerOptions() {
      return {
        start: this.settings?.callcenter_calendar_start + ':00',
        end: this.settings?.callcenter_calendar_end + ':00',
        step: '00:30',
      }
    }
  },
  methods: {
    validate() {
      if (this.reactivationStatus !== this.leadStatuses.free.value) {
        return new Promise((resolve, reject) => {
          this.$refs.reactivateModal.validate()
            .then((success) => {
              if (success) {
                resolve({
                  deadline: this.$moment(this.recallDate + ' ' + this.recallTime).format('YYYY-MM-DD HH:mm:ss'),
                  operatorId: this.operatorId,
                });
              }
            })
            .catch((err) => {
              reject(err);
            });
        });
      }

      return null;
    }
  }
}
</script>

<style scoped>

</style>
