<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <tabs centered square>
          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('callcenter.lead') }}</div>
              </div>
            </template>
            <octo-header-button
              :title="registry.surname + ' ' + registry.name"
              :buttons="leadButtons"
              @onReactivateLead="modalKey++; showModal = true;"
              @onOpenOctoEmail="modalKey++; showModalEmail = true"
            />
            <lead-tab-pane :read-only="false" :lead="lead" :key="`lead-tab-pane-${leadDataKey}`"/>
          </tab-pane>

          <tab-pane>
            <template slot="label">
              <div class="d-flex align-items-center">
                <div class="text-uppercase m-3">{{ $t('common.registry') }}</div>
              </div>
            </template>
            <octo-header-button
              :title="registry.surname + ' ' + registry.name"
              :buttons="registryButtons"
              @onOpenRegistry="$router.push({name: 'registries.show', params: {id: registry.id}})"
            />
            <registry-tab-pane
              :registry="registry"
              :key="`registry-tab-pane-${leadDataKey}`"
              v-on:onSetEmails="handleOnSetEmails"
            />
          </tab-pane>
        </tabs>
      </div>
    </div>
    <modal centered :show.sync="showModal">
      <h5 slot="header" class="modal-title text-uppercase">
        {{ $t('lead.lead_reactivation') }}
      </h5>
      <reactivate-lead-modal ref="reactivateLeadModal" :key="`modal-${modalKey}`"/>
      <template slot="footer">
        <base-button link @click="reactivateLead">
          <div class="text-capitalize">{{ $t('common.reactivate') }}</div>
        </base-button>
      </template>
    </modal>
    <octo-email
      :target-id="lead.id"
      :emails="registry.emails"
      :allowed-email-templates="[emailTemplateName.GENERIC, emailTemplateName.SALE_APPOINTMENT]"
      :show-modal="showModalEmail"
      :target-type="emailTargetType.LEAD"
      :key="`email-modal-${modalKey}`"
      @onClose="showModalEmail = false"
      @emailSent="leadDataKey++; showModalEmail = false"
    />
  </div>
</template>

<script>
import {endpoints} from "@/routes/endpoints";
import {TabPane, Tabs} from '@/components';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import Lead from "@/models/lead";
import BaseAlert from "@/components/BaseAlert";
import LeadStatusContainer from "@/pages/Leads/components/LeadStatusContainer";
import Deal from "@/models/deal";
import {permissionService} from "@/util/permission-service";
import OctoHeaderButton from "@/components/octo-header-buttons/OctoHeaderButton";
import LeadStatuses from "@/pages/CallCenter/resources/leadStatuses";
import ReactivateLeadModal from "@/pages/Leads/components/ReactivateLeadModal";
import Modal from "@/components/Modal";
import RegistryTabPane from "@/components/TabPanels/RegistryTabPane";
import LeadTabPane from "@/components/TabPanels/LeadTabPane";
import Registry from "@/models/registry";
import {mapActions} from "vuex";
import OctoEmail, {EMAIL_TEMPLATE_NAME, EMAIL_TARGET_TYPE} from "@/components/octo-email/OctoEmail";

export default {
  name: "LeadArchiveShowPage",
  components: {
    OctoEmail,
    LeadTabPane,
    RegistryTabPane,
    Modal,
    ReactivateLeadModal,
    OctoHeaderButton,
    LeadStatusContainer,
    BaseAlert,
    OctoIcon,
    Tabs,
    TabPane,
  },
  data() {
    return {
      endpoints: endpoints,
      lead: this.$_.cloneDeep(Lead),
      deal: this.$_.cloneDeep(Deal),
      isBalanced: false,
      leadDataKey: 1,
      permissionService: permissionService,
      showModal: false,
      showModalEmail: false,
      modalKey: 1,
      emailTemplateName: EMAIL_TEMPLATE_NAME,
      emailTargetType: EMAIL_TARGET_TYPE,

    }
  },
  beforeMount() {
    this.$fullLoading.show();
    // this.getEmailTemplates();
    this.$api.get(endpoints.LEAD_SHOW.replace('{id}', this.$route.params.id))
      .then((resp) => {
        this.lead = resp?.data?.data || this.$_.cloneDeep(Lead);
        this.deal = resp?.data?.deal || this.$_.cloneDeep(Deal);
        this.leadDataKey++;
      })
      .catch(() => {
        this.$router.push({name: 'leads.archive'})
        this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
      })
      .finally(() => {
        this.$fullLoading.hide();
      })
  },
  computed: {
    leadButtons: function () {
      const buttons = [];

      if (
        [LeadStatuses.invalid.value, LeadStatuses.not_interested.value].indexOf(this.lead.status) !== -1
        && permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE])
      ) {
        buttons.push({
          label: 'reactivate',
          onClick: 'onReactivateLead'
        });
      }

      buttons.push({
        label: 'send_email',
        onClick: 'onOpenOctoEmail'
      });

      return buttons;
    },
    registryButtons: function () {
      const buttons = [];

      if (permissionService.isAllowed([permissionService.CALL_CENTER_MANAGER_ROLE])) {
        buttons.push({
          onClick: 'onOpenRegistry',
          label: 'open'
        });
      }

      return buttons;
    },
    registry() {
      return this.$_.cloneDeep(this.lead.registry || Registry);
    }
  },
  methods: {
    ...mapActions({
      getEmailTemplates: 'common/getEmailTemplates',
    }),


    async reactivateLead() {
      try {
        const data = await this.$refs.reactivateLeadModal.validate();

        this.$fullLoading.show();
        const resp = await this.$api.post(
          endpoints.LEAD_REACTIVATE.replace('{id}', this.lead.id),
          data
        );

        this.lead = resp?.data || this.$_.cloneDeep(Lead);
        this.headerButtons = [{
          label: 'save',
          onClick: 'onSaveLead'
        }];
        this.leadDataKey++;
        this.showModal = false;
        this.$notify({type: 'success', message: this.$t('notifications.saved_success')});
      } catch (e) {
        this.$notify({type: 'danger', message: this.$t('notifications.saved_error')});
      } finally {
        this.$fullLoading.hide();
      }
    },

    handleOnSetEmails(emails){
      this.registry.emails = emails;
    }
  }
}
</script>

<style scoped>

</style>
