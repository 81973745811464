<template>
  <p>
    <small> {{ $t('common.switch_to') }}</small>
      {{ users[entity] | optional('firstname') }}
      {{ users[entity] | optional('lastname') }}
  </p>
</template>

<script>
import LeadEventLog from "@/models/leadEventLog";
import {mapGetters} from "vuex";

export default {
  name: "LeadHistoryUser",
  data(){
    return {
      entity:0
    }
  },
  props: {
    leadEventLog: {
      type: Object,
      default: () => this.$_.cloneDeep(LeadEventLog)
    }
  },
  mounted() {
    this.entity = this.leadEventLog.loggable_id;
  },
  computed:{
    ...mapGetters({
      users: 'common/users'
    }),
  },
}
</script>

<style scoped>

</style>
