<template>
    <base-alert :type="alertType" class="py-4">
      <div class="text-white text-center text-uppercase" v-if="localStatus">
        {{ $t('callcenter.status.' + localStatus) }}
      </div>
      <div class="text-center text-uppercase small" v-if="localSubStatus">
        {{ $t('callcenter.status.' + localSubStatus) }}
      </div>
    </base-alert>
</template>

<script>

import BaseAlert from "@/components/BaseAlert";
import Lead from "@/models/lead";
export default {
  name: "LeadStatusContainer",
  components: {BaseAlert},
  data() {
    return {
      localStatus: null,
      localSubStatus: null,
    }
  },
  props: {
    lead: {
      type: Object,
      default: () => this.$_.cloneDeep(Lead)
    },

  },
  computed: {
    alertType: function () {
      return this.localStatus ? this.$options.filters.leadStatusColor(this.localStatus) : 'info';
    }
  },
  mounted() {
    this.localStatus = this.$_.clone(this.lead.status);
    this.localSubStatus = this.$_.clone(this.lead.sub_status);
  }
}
</script>

<style scoped>

</style>
