import LeadHistoryCampaign from "../OperatorComponents/LeadHistoryCampaign";
import LeadHistoryStatusTransition from "../OperatorComponents/LeadHistoryStatusTransition";
import LeadHistoryEmail from "../OperatorComponents/LeadHistoryEmail";
import LeadHistoryCall from "../OperatorComponents/LeadHistoryCall";
import LeadHistoryUser from "../OperatorComponents/LeadHistoryUser";
import LeadHistoryArchiveEmail from "../OperatorComponents/LeadHistoryArchiveEmail";

const LeadHistoryComponentConst = {
  Campaign: {
    titleLang: 'fields.campaign',
    color: 'danger',
    component: LeadHistoryCampaign,
    icon: 'email',
  },
  LeadStatusTransition: {
    titleLang: 'callcenter.lead_status_transition',
    color: 'success',
    component: LeadHistoryStatusTransition,
    icon: 'sync',
  },
  CallCenterEmail: {
    titleLang: 'callcenter.email_sent',
    component: LeadHistoryEmail,
    color: 'info',
    icon: 'email',
  },
  EmailArchive: {
    titleLang: 'callcenter.email_sent',
    component: LeadHistoryArchiveEmail,
    color: 'info',
    icon: 'email',
  },
  CallCenterCall: {
    titleLang: 'callcenter.call_made',
    component: LeadHistoryCall,
    color: 'warning',
    icon: 'telephone',
  },
  User: {
    titleLang: 'callcenter.change_operator',
    component: LeadHistoryUser,
    color: 'warning',
    icon: 'sync',
  },
}

export default LeadHistoryComponentConst
