<template>
  <div class="row">
    <div class="col-12 d-flex justify-content-between align-items-center">
      <div>
        <div class="text-uppercase" v-if="entity.status_from">
          {{ $t('callcenter.status.' + entity.status_from) }}
        </div>
        <div v-if="entity.sub_status_from" class="small text-muted">
          {{ $t('callcenter.status.' + entity.sub_status_from) }}
        </div>
      </div>
      <div>
        <octo-icon icon="right-arrow"/>
      </div>
      <div>
        <div class="text-uppercase" v-if="entity.status_to">
          {{ $t('callcenter.status.' + entity.status_to) }}
        </div>
        <div v-if="entity.sub_status_to" class="small text-muted">
          {{ $t('callcenter.status.' + entity.sub_status_to) }}
        </div>
      </div>
    </div>
    <label-theme-component class="col-12 small text-right mt-2" v-if="entity.notes">
      {{ entity.notes }}
    </label-theme-component>
  </div>
</template>

<script>

import LeadStatusTransition from "@/models/leadStatusTransition";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LeadEventLog from "@/models/leadEventLog";
import LabelThemeComponent from "@/components/LabelThemeComponent";

export default {
  name: "LeadHistoryStatusTransition",
  components: {LabelThemeComponent, OctoIcon},
  data(){
    return{
      entity:this.$_.cloneDeep(LeadStatusTransition)
    }
  },
  props: {
    leadEventLog: {
      type: Object,
      default: () => this.$_.cloneDeep(LeadEventLog)
    }
  },
  mounted() {
    this.entity = this.leadEventLog.leadEventLoggable
  },
}
</script>

<style scoped>

</style>
