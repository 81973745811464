import User from "@/models/user";
import _ from "lodash";

const LeadEventLog = {
  id: null,
  date: null,
  time: null,
  loggable_type: null,
  loggable_id:null,
  leadEventLoggable: {},
  user: _.cloneDeep(User)
};

export default LeadEventLog
