const LeadStatusTransition = {
  id: null,
  lead_id: null,
  user_id: null,
  status_from: null,
  sub_status_from: null,
  status_to: null,
  sub_status_to: null,
  notes: null
};

export default LeadStatusTransition
