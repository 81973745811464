const CallCenterCall = {
  id : null,
  date_start: null,
  time_start: null,
  date_end: null,
  time_end: null,
  device: null,
  number: null,
  call_id: null
};

export default CallCenterCall
