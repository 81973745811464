<template>
  <div class="row">
    <div class="col-md-6">
      <lead-card :read-only="readOnly" :lead="lead"/>
    </div>
    <div class="col-md-6">
      <lead-status-container :lead="lead"/>
      <lead-history :lead-id="lead.id"/>
    </div>
  </div>
</template>

<script>
import LeadCard from "@/pages/Leads/components/LeadCard";
import LeadHistory from "@/pages/CallCenter/OperatorComponents/LeadHistory";
import Lead from "@/models/lead";
import LeadStatusContainer from "@/pages/Leads/components/LeadStatusContainer";

export default {
  name: "LeadTabPane",
  components: {
    LeadStatusContainer,
    LeadCard,
    LeadHistory
  },
  props: {
    lead: {
      type: Object,
      default: () => this.$_.cloneDeep(Lead)
    },
    readOnly: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style scoped>

</style>
