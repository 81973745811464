<template>
  <ul class="list-group list-group-flush">
    <list-group-item-component :label="`${$t('fields.phone')}:`" :value="entity.number"/>
  </ul>
</template>

<script>
import CallCenterCall from "@/models/callCenterCall";
import LeadEventLog from "@/models/leadEventLog";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";

export default {
  name: "LeadHistoryCall",
  components: {ListGroupItemComponent},
  data() {
    return {
      entity: this.$_.cloneDeep(CallCenterCall)
    }
  },
  props: {
    leadEventLog: {
      type: Object,
      default: () => this.$_.cloneDeep(LeadEventLog)
    }
  },
  mounted() {
    this.entity=this.leadEventLog.leadEventLoggable
  }
}
</script>

<style scoped>

</style>
