<template>
  <div>
    <div class="small">Email: {{entity.receiver}}</div>
    <div class="small text-muted" v-if="entity.cc.length > 0">
      cc: {{entity.cc.join(', ')}}
    </div>
    <div class="small text-muted">
      {{ $t('callcenter.subject') }}: {{ entity.subject }}
    </div>
  </div>
</template>

<script>
import CallCenterEmail from "@/models/callCenterEmail";
import LeadEventLog from "@/models/leadEventLog";

export default {
  name: "LeadHistoryEmail",
  data(){
    return {
      entity: this.$_.cloneDeep(CallCenterEmail)
    }
  },
  props: {
    leadEventLog: {
      type: Object,
      default: () => this.$_.cloneDeep(LeadEventLog)
    }
  },
  mounted() {
    this.entity = this.leadEventLog.leadEventLoggable
  },
  computed: {
    
  }
}
</script>

<style scoped>

</style>
