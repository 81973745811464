<template>
  <div>
    <octo-loader v-if="isLoading"/>
    <no-data-component v-if="leadEventLogs.length === 0 && !isLoading"
                       :label="$t('common.no_history')"/>
    <time-line type="simple" class="wizard-timeline" v-if="leadEventLogs.length && !isLoading">
      <time-line-item
        inverted
        :badge-type="leadHistoryComponentConst[event.loggable_type].color"
        :badge-icon="leadHistoryComponentConst[event.loggable_type].icon"
        v-for="(event, index) in eventsList"
        v-bind:key="`event-${index}`"
      >
        <div slot="header" class="d-flex justify-content-between align-items-center">
          <div :class="`badge badge-${leadHistoryComponentConst[event.loggable_type].color}`">
            {{ $t(leadHistoryComponentConst[event.loggable_type].titleLang) }}
          </div>
          <div class="text-muted">
            {{ event.date | date }} <span class="small">{{ event.time | time }}</span>
          </div>
        </div>
        <div slot="content">
          <p v-if="permissionService.isAllowed([
              permissionService.SALES_MANAGER_ROLE,
              permissionService.CALL_CENTER_OPERATOR_ROLE
            ])"
          >
            <small>
              {{ $t('common.powered_by') }}
              {{ users[event.user_id] | optional('firstname') }}
              {{ users[event.user_id] | optional('lastname') }}
            </small>
          </p>
          <component
            :key="event.id"
            :id = "event.id"
            :is="leadHistoryComponentConst[event.loggable_type].component"
                     :leadEventLog="event"/>
        </div>
      </time-line-item>
    </time-line>

    <div class="row" v-if="eventsPages.length > 1">
      <div class="col-12 d-flex justify-content-end">
        <card class="card card-plain mb-1" body-classes="standard-card-body">
          <base-pagination class="mb-0" v-model="currentPage" :per-page=numberItemsPerPage :total="leadEventLogs.length"/>
        </card>
      </div>
    </div>

  </div>
</template>

<script>

import {TimeLine, TimeLineItem} from 'src/components';
import OctoIcon from "@/components/octo-icon/OctoIcon";
import LeadHistoryComponentConst from "../resources/leadHistoryComponentConst";
import BaseAlert from "@/components/BaseAlert";
import {mapGetters} from "vuex";
import BasePagination from "@/components/BasePagination";
import {endpoints} from "@/routes/endpoints";
import OctoLoader from "@/components/octo-loader/OctoLoader";
import NoDataComponent from "@/components/NoDataComponent";
import {permissionService} from "@/util/permission-service";
import leadHistoryComponentConst from "../resources/leadHistoryComponentConst";

export default {
  name: "LeadHistory",
  components: {
    NoDataComponent,
    OctoLoader,
    BasePagination,
    BaseAlert,
    OctoIcon,
    TimeLine,
    TimeLineItem,
    LeadHistoryComponentConst
  },
  data() {
    return {
      leadHistoryComponentConst: LeadHistoryComponentConst,
      currentPage: 1,
      leadEventLogs: [],
      isLoading: false,
      permissionService: permissionService
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users'
    }),
    eventsPages(){
      return this.$_.chunk(this.leadEventLogs,this.numberItemsPerPage);
    },
    eventsList() {
      return this.eventsPages[this.currentPage-1];
    }
  },
  props: {
    leadId: {
      default: null
    },
    numberItemsPerPage:{
      type: Number,
      default: 5
    }
  },
  mounted() {
    if (this.leadId) {
      this.isLoading = true;

      this.$api.get(endpoints.LEAD_GET_LEAD_EVENTS_LOG.replace('{id}', this.leadId))
        .then((resp) => {
          this.leadEventLogs = resp.data;
        })
        .catch(() => {
          this.$notify({type: 'danger', message: this.$t('notifications.loading_error')});
        })
        .finally(() => {
          this.isLoading = false;
        });
    }
  },
  methods: {
    getComponent(type) {
      leadHistoryComponentConst[event.loggable_type].component
    }
  }
}
</script>

<style scoped lang="scss">
</style>
