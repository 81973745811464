<template>
  <div class="text-uppercase">{{ entity.name }}</div>
</template>

<script>
import Campaign from "@/models/campaign";
import LeadEventLog from "@/models/leadEventLog";

export default {
  name: "LeadHistoryCampaign",
  data() {
    return {
      entity: this.$_.cloneDeep(Campaign)
    }
  },
  props: {
    leadEventLog: {
      type: Object,
      default: () => this.$_.cloneDeep(LeadEventLog)
    }
  },
  mounted() {
    this.entity = this.leadEventLog.leadEventLoggable
  }
}

</script>

<style scoped>

</style>
