import Lead from "@/models/lead";

const CallCenterEmail = {
  id : null,
  subject: '',
  receiver: '',
  message: '',
  date: '',
  time: '',
  cc: [],
  lead: Lead,
};

export default CallCenterEmail
